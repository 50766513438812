/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $(document).on('click',function(){
          $('.collapse').collapse('hide');
        });

        $(".eed-fancybox").fancybox({
          maxWidth  : 900,
          maxHeight : 600,
          fitToView : false,
          width   : '90%',
          height    : '90%',
          autoSize  : false,
          closeClick  : true,
          openEffect  : 'elastic',
          closeEffect : 'elastic',
          helpers:
          {
            overlay: {
              css: { 'background': 'rgba(0, 0, 0, 0.5)' }
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        var $gallery = $('.main-carousel').flickity({
          cellSelector: '.gallery-cell',
          imagesLoaded: true,
          percentPosition: false,
          cellAlign: 'center', 
          contain: true,
          wrapAround: true
        });

        var $caption = $('.caption .align');
        var flkty = $gallery.data('flickity');

        $gallery.on( 'cellSelect', function() {
          var el = flkty.selectedElement;
          var imgel = el.getElementsByTagName('img')[0];
          var extraText = '';
          if (imgel.alt === 'EED Territories') {
            extraText = ' | <a href="/contact-us/" style="color: #fff;">Contact Us <i class="fa fa-angle-double-right" aria-hidden="true"></i></a>';
          }
          var caption = imgel.alt + extraText;
          $caption.html(caption);
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'manufacturers': {
      init: function() {
        
        $(".gallery-item").click(function() {
          
          var url = $(this).find("img").attr("alt");
          console.log(url);

          window.location = document.location.origin + url;
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
